import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-facing-decorator';
import WSelect from "@/components/form/w-select.vue";
import WInput from "@/components/form/w-input.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import Icon from "@/components/icon/Icon.vue";
import axios from "axios";
import LightButton from "@/components/buttons/LightButton.vue";
import Loader from "@/components/loader/Loader.vue";
import delay from "delay";
import { Events } from "@/events/events";
let CreateAudioFromText = class CreateAudioFromText extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "chapterId", void 0);
    // url of the blob
    _defineProperty(this, "urlBlob", null);
    _defineProperty(this, "waitingTranscoding", false);
    _defineProperty(this, "langTxtSelected", "");
    _defineProperty(this, "textToGenerate", "");
    _defineProperty(this, "jobIdVoicePreview", "");
    _defineProperty(this, "loadingVoiceFromText", false);
    _defineProperty(this, "timeoutWaitingTextGeneration", 0);
  }
  get listOfLang() {
    return [{
      "value": "en-US",
      "label": this.$t('lang.en-US')
    }, {
      "value": "en-GB",
      "label": this.$t('lang.en-UK')
    }, {
      "value": "fr-FR",
      "label": this.$t('lang.fr-FR')
    }, {
      "value": "es-ES",
      "label": this.$t('lang.es-ES')
    }, {
      "value": "de-DE",
      "label": this.$t('lang.de-DE')
    }, {
      "value": "pt-PT",
      "label": this.$t('lang.pt-PT')
    }, {
      "value": "it-IT",
      "label": this.$t('lang.it-IT')
    }, {
      "value": "zh-CN",
      "label": this.$t('lang.zh-CN')
    }];
  }
  /**
   * Get the audioplayer from refs
   * @private
   */
  audioPlayer() {
    return this.$refs['audioPlayer'];
  }
  // private cancelTxtVoiceAI(): void {
  //   this.currentStep = 0;
  //   this.blobRecorded = null;
  //   this.urlBlob = null;
  //   this.reinitPlayer()
  // }
  previewVoiceAI() {
    if (this.textToGenerate) {
      clearTimeout(this.timeoutWaitingTextGeneration);
      const urlPreviewVoice = "/api/v2/chapter/voice/text/" + this.chapterId;
      const formData = new FormData();
      formData.append('text', this.textToGenerate);
      formData.append('jobID', this.jobIdVoicePreview);
      formData.append('lang', this.langTxtSelected);
      this.loadingVoiceFromText = true;
      axios.post(urlPreviewVoice, formData).then(async response => {
        if (response.status == 200) {
          this.loadingVoiceFromText = false;
          this.urlBlob = response.data;
          this.jobIdVoicePreview = '';
          const audioPlayer = this.audioPlayer();
          if (audioPlayer) {
            audioPlayer.autoplay = true;
            audioPlayer.volume = 1;
            audioPlayer.currentTime = 0;
            audioPlayer.play();
          }
        } else if (response.status == 202) {
          this.jobIdVoicePreview = response.data;
          this.timeoutWaitingTextGeneration = setTimeout(() => {
            this.previewVoiceAI();
          }, 2000);
        }
      }).catch(error => {
        clearTimeout(this.timeoutWaitingTextGeneration);
        console.error('Error on preview generated voice', error);
      });
    } else {
      console.error('Error on preview generated voice no voice to generate');
    }
  }
  confirmTxtVoiceAI() {
    if (this.urlBlob) {
      const urlTxtVoiceOver = "/recorder/copyGeneratedAudio/" + this.chapterId;
      const formData = new FormData();
      formData.append('urlFileAudio', this.urlBlob);
      axios.post(urlTxtVoiceOver, formData).then(() => {
        trig(Events.SAVE_AUDIO_FROM_TEXT, 'progressAudioUpload');
        this.onClose();
      }).catch(err => {
        console.log("Error on validate text voiceOver", err);
      });
      return;
    }
  }
  cancelTxtVoiceAI() {
    this.urlBlob = null;
    this.onClose();
  }
  //****************************************
  //* EVENT METHODS
  //*****************************************/
  async onClose() {
    trig(Events.CLOSE_AI_AUDIO_POPIN);
    this.waitingTranscoding = true;
    // waiting transcoding start
    await delay(3000);
    return;
  }
  beforeDestroy() {
    clearTimeout(this.timeoutWaitingTextGeneration);
  }
};
__decorate([Prop({
  required: true
})], CreateAudioFromText.prototype, "chapterId", void 0);
__decorate([Emit('onClose')], CreateAudioFromText.prototype, "onClose", null);
CreateAudioFromText = __decorate([Component({
  components: {
    Icon,
    Loader,
    LightButton,
    StandardButton,
    WInput,
    WSelect
  }
})], CreateAudioFromText);
export default CreateAudioFromText;